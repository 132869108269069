interface Validator {
  regex: string;
  errorMessage: string
}

window.addEventListener("DOMContentLoaded", () => {
  let submitButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".FormSubmitButton.button") as NodeListOf<HTMLButtonElement>;
  
  // Scroll to the top of the form when the submit button is clicked
  submitButtons.forEach((submitButton: HTMLButtonElement) => {
    if (!!submitButton) {
      submitButton.addEventListener("click", () => {
        let form: HTMLFormElement = submitButton.closest(".EPiServerForms") as HTMLFormElement;
        window.scrollTo({top: form.offsetTop - 200, behavior: 'smooth'})
      });
    }
  });
  
  updateValidationErrorSpanElements();
  checkRequiredFieldsHasContent();

  let textBoxes: NodeListOf<HTMLElement> = document.querySelectorAll(".FormTextBox");
  validateTextElements(textBoxes);

  let textAreas: NodeListOf<HTMLElement> = document.querySelectorAll(".FormTextbox--Textarea");
  validateTextElements(textAreas);

  let urlFields: NodeListOf<HTMLElement> = document.querySelectorAll(".FormTextbox--Url");
  validateTextElements(urlFields);

  let numberFields: NodeListOf<HTMLElement> = document.querySelectorAll(".FormTextbox--Number");
  validateTextElements(numberFields);

  let selectionFields: NodeListOf<HTMLElement> = document.querySelectorAll(".FormSelection");
  validateSelectionElements(selectionFields);

  let choiceFields: NodeListOf<HTMLElement> = document.querySelectorAll(".FormChoice")
  validateChoiceElements(choiceFields);
})

function validateChoiceElements(nodeList: NodeListOf<Element>) {
  nodeList.forEach((element: HTMLElement | any) => {
    if (element.classList.contains("form-validation--required")) {
      const validators = JSON.parse(element.dataset.validators);
      let inputFields = element.querySelectorAll(".checkbox__input, .radio-button__input")
      let spanElement = element.querySelector(".Form__Element__ValidationError");

      validators.forEach((validator: Validator) => {
        inputFields.forEach((field: HTMLElement | any, index: number) => {
          field.addEventListener("change", () => {
            let inputFields = element.querySelectorAll(".checkbox__input:checked, .radio-button__input:checked");
            if (inputFields.length == 0) {
              element.classList.add("form-validation--required")
              addErrorValidationClassesToSpanElements(spanElement, validator.errorMessage);
            }
            else {
              spanElement?.classList.remove("validation-message--error");
              element.classList.remove("form-validation--required")

              spanElement.style.display = "none";
            }
            checkRequiredFieldsHasContent();
          });
          if (index == inputFields.length - 1)
          {
            field.addEventListener("blur", () => {
              let inputFields = element.querySelectorAll(".checkbox__input:checked, .radio-button__input:checked");
              if (inputFields.length == 0) {
                element.classList.add("form-validation--required")
                addErrorValidationClassesToSpanElements(spanElement, validator.errorMessage);
              }
            })
          }
        })
      })
    }
  })
}


function validateSelectionElements(nodeList: NodeListOf<HTMLElement>) {
  nodeList.forEach((element: HTMLElement | any) => {
    const validators = JSON.parse(element.dataset.validators);
    let dropdownSelect = element.querySelector(".dropdown__select");
    let spanElement = element.querySelector(".Form__Element__ValidationError");

    validators.forEach((item: Validator) => {
      const regex = new RegExp(item.regex);

      dropdownSelect.addEventListener("blur", () => {
        const value = dropdownSelect.value;
        if (regex.test(value)) {
          // Remove "invalid" classes
          element.classList.remove("input-text--invalid");
          spanElement?.classList.remove("validation-message--error");
          dropdownSelect.classList.remove("form-validation--required")

          // Add "valid" classes
          element.classList.add("input-text--valid")
          spanElement.style.display = "none";
        } else {
          // Remove "valid" classes
          element.classList.remove("input-text--valid");

          // Add "invalid" classes
          element.classList.add("input-text--invalid");
          dropdownSelect.classList.add("form-validation--required")
          addErrorValidationClassesToSpanElements(spanElement, item.errorMessage);
        }

        checkRequiredFieldsHasContent();
      })
    })
  })
}
function validateTextElements(nodeList: NodeListOf<HTMLElement>) {
  nodeList.forEach((element: HTMLElement | any) => {
    const validators = JSON.parse(element.dataset.validators);
    let inputField: HTMLInputElement | any = element.querySelector(".FormTextbox__Input");
    let spanElement = element.querySelector(".Form__Element__ValidationError");

    validators.forEach((item: Validator) => {
      const regex = new RegExp(item.regex);

      inputField.addEventListener("blur", () => {
        const value = inputField.value;
        if (regex.test(value)) {
          // Remove "invalid" classes
          element.classList.remove("input-text--invalid");
          spanElement?.classList.remove("validation-message--error");
          inputField.classList.remove("form-validation--required")

          // Add "valid" classes
          element.classList.add("input-text--valid")
          inputField?.classList.add("input-text__input--with-icon");
          spanElement.style.display = "none";
        } else {
          // Remove "valid" classes
          element.classList.remove("input-text--valid");

          // Add "invalid" classes
          element.classList.add("input-text--invalid");
          inputField?.classList.add("input-text__input--with-icon");
          inputField.classList.add("form-validation--required")
          addErrorValidationClassesToSpanElements(spanElement, item.errorMessage);
        }

        checkRequiredFieldsHasContent();
      });
    })


  })
}

function addErrorValidationClassesToSpanElements(spanElement: HTMLSpanElement, errorMessage: string) {
  spanElement.style.display = "block";
  spanElement.textContent = errorMessage;
  spanElement?.classList.add("validation-message");
  spanElement?.classList.add("validation-message--error");
  spanElement?.setAttribute('aria-live', 'polite');
}

function checkRequiredFieldsHasContent() {
  const formContainers = document.querySelectorAll(".EPiServerForms");
  formContainers.forEach((form: HTMLElement | any) => {
    const requiredFields = form.querySelectorAll(".form-validation--required");
    let submitButton: HTMLButtonElement | any = form.querySelector(".FormSubmitButton");

    // Only do this check if a form exists
    if (requiredFields.length < 1 && !!submitButton) {
      submitButton.disabled = false;
    }

    requiredFields.forEach((field: HTMLElement | any) => {
      if (field instanceof HTMLInputElement && !field.textContent) {
        submitButton.disabled = true;
      }
      else if (field instanceof HTMLSelectElement && field.selectedIndex < 1) {
        submitButton.disabled = true;
      }
      else if (field.classList.contains("FormChoice")) {
        let inputFields = field.querySelectorAll(".checkbox__input:checked, .radio-button__input:checked");
        if (!!inputFields) {
          submitButton.disabled = true;
        }
      }
    })
  });
}

function updateValidationErrorSpanElements() {
  document.querySelectorAll(".Form__Element__ValidationError").forEach((span: HTMLSpanElement | any) => {
    // Check if the span is visible, that means that the form was submitted but an error occurred
    if (span.style.display != "none") {
      let parent = span.parentElement;
      let sibling = span.nextElementSibling;
      if (sibling instanceof HTMLInputElement) {
        sibling.classList.add("input-text__input--with-icon");
      }
      parent?.classList.add("input-text--invalid")
      span.classList.add("validation-message");
      span.classList.add("validation-message--error");
      span.setAttribute('aria-live', 'polite');
    }
  })
}